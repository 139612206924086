<template>
  <div>
    <div class="dispflex" style="margin-right: 2%;margin-left: 1%;">
      <div class="dk_icon" style="width: 80%">
        <div style="width: 14%">
                    <span class="has-float-label">
                      <select
                        @change="getExamAcademicInfo(selectTerm)"
                        class="form-control form-input"
                        id="deptlistid"
                        v-model="selectTerm"
                      >
                        <option :value="null">-- Select --</option>
                        <option
                          v-for="(item, index) in terms"
                          :key="index"
                          :value="item._id"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                      <label for="clslistid">Select Term </label>
                    </span>
                  </div>
      </div>
    
 
    
    </div>
  
    <div
  class="row"
  style="margin-top: 10px"
  
>
  <div class="col-lg-12">
    <div class="widjet">
      <div class="admintbl pb-3 mt-1">
        <div>
          <div class="row cust-bg" style="margin-right: 0px;margin-left: 0px;">
            <div class="col-2 cell" >Exam Name</div>
            <div class="col-1 cell"></div>
            <div class="col-2 cell">Exam Start Date</div>
            <div class="col-1 cell"></div>
            <div class="col-2 cell">Exam End Date</div>
            <div class="col-2 cell"></div>
            <div class="col-2 cell">Action</div>
          </div>
          <div v-for="(data, index) in examAcademicList" :key="index">
            <div class="row cust-lr" style="margin-right: 0px;margin-left: 0px;">
              <div
                class="col-2 cell"
                style="font-weight: bold; padding-left: 2%"
              >
                {{ data.name }}
              </div>
              <div class="col-1 cell"></div>
              <div class="col-2 cell">
                {{ data.startDate | dateFormat }}
              </div>
              <div class="col-1 cell"></div>
              <div class="col-2 cell">
                {{ data.endDate | dateFormat }}
              </div>
              <div class="col-2 cell"></div>

              <div class="col-2 cell">
                <div class="dispflex" style="justify-content: stretch">
                  <button
                    v-if="data.isView"
                    class="btn round orange tooltipt"
                    style="background: #b3a995"
                    @click="showViewClose(index)"
                  >
                    <i class="fas fa-angle-up"></i>
                  </button>
                  <button
                    v-if="!data.isView"
                    class="btn round orange tooltipt"
                    style="background: #b3a995"
                    @click="showView(index)"
                  >
                    <i class="fas fa-angle-down"></i>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="data.isView">
              <div
                class="row cust-lr"
                style="font-weight: bold; padding-left: 1%;background-color: palegoldenrod;margin-right: 0px;margin-left: 0px;"
              >
                <div class="col-2 cell">Subject</div>
                <div class="col-2 cell">Exam Date</div>

                <div class="col-2 cell">Time</div>
                <div class="col-2 cell">Duration</div>
                <div class="col-2 cell">Total Marks</div>
                <div class="col-2 cell">
                  
                </div>
              </div>
              <div
                class="row cust-lr" style="background-color: #efe9e9;margin-right: 0px;margin-left: 0px;"
                v-for="(ter, issndex) in data.examSubject"
                :key="issndex"
              >
                <div class="col-2 cell" style="padding-left: 2%">
                  {{ ter.subjectName }}
                </div>

                <div class="col-2 cell" v-if="ter.examDate">
                  {{ ter.examDate | dateFormat  }}
                 
                </div>
                <div class="col-2 cell" v-else style="padding-left: 39px;">
                 -
                  
                </div>
                <div class="col-2 cell">
                  {{ ter.time || '-' }}
                  
                </div>
                <div class="col-2 cell" style="margin-left: 20px;">
                  {{ ter.duration || '-' }}
                
                </div>
                <div class="col-2 cell" style="margin-left: 28px;">
                  {{ ter.totalMarks || '-' }}
                 
                </div>
                <div class="col-2 cell"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  
</div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import ViService from "@/services/ViService";
import secureUI from "../../../utils/secureUI";
import draggable from "vuedraggable";

export default {
name: "teacher",
props: {
  classId: {
      type: String,
    },
    classRoom: {
      type: String,
    },
    terms: {
      type: Array,
    },
    accountId: {
      type: String,
    },
    academicYearId: {
      type: String,
    }
},
data() {
  return {
    teacherList: [],
    teacherSubjectList: [],
    subjectList: [],
    isEdit: false,
    selectTerm: null,
    examAcademicList: []
  }
  },
created() {
  // this.getTeacherList();
  // this.getSubjectList()
  // this.getSectionData()
  this.initComponent();

 // this.getGradeSetupDetails();

},
computed: {
  styles() {
    var brwHeight = window.innerHeight;
    return {
      height: brwHeight - 90 + "px",
    };
  },
},

methods: {
  async initComponent(){
      await this.initComponentView()
    },
    initComponentView(){
     
     if (this.terms.length > 0) {
            if (this.terms.length == 1) {
            this.selectTerm = this.terms[0]._id;
            this.getExamAcademicInfo(this.selectTerm)
            
            }else{
            let currentDate = new Date()

            let checkData = this.terms.filter( x => (currentDate >= new Date(x.startDate)) && (currentDate <= new Date(x.endDate)))
            if(checkData.length > 0){

              this.selectTerm = checkData[0]._id;
              this.getExamAcademicInfo(this.selectTerm)

              var startDate = new Date(checkData[0].startDate);
              var endDate = new Date(checkData[0].endDate);

              // this.minDate = this.dateFormat(startDate)
          
              // this.maxDate =  this.dateFormat(endDate)

            }else{
              let checkfutureDates = this.terms.filter( x => (new Date(x.startDate) > currentDate))
             if(checkfutureDates.length > 0){
              this.selectTerm = checkfutureDates[0]._id;
              this.getExamAcademicInfo(this.selectTerm)

              // var startDate = new Date(checkfutureDates[0].startDate);
              // var endDate = new Date(checkfutureDates[0].endDate);

              // this.minDate = this.dateFormat(startDate)
          
              // this.maxDate =  this.dateFormat(endDate)
             }else{
              this.selectTerm = this.terms[0]._id;
              this.getExamAcademicInfo(this.selectTerm)

            }
            }
           
            }
          }
    },
    async getExamAcademicInfo(id) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        if (id) {
          let requestBody = {
            class: this.classId,
            term: id,
          };

          const response = await ViService.viXPost(
            "/academic/getStudentExamScheduleInfo",
            requestBody,
            userData.token
          );

          if (response.isSuccess) {
            const resultData = secureUI.secureGet(response.data);
            
            this.examAcademicList = resultData;
          
          } else {
            this.$toasted.error(response.message);
          }
        } else {
          (this.examAcademicList = []), (this.examCurricularList = []);
        }
      }
    },
  showView(index) {
      this.examAcademicList.forEach((x, i) => {
        if (i == index) {
          x.isView = true;
        } else {
          x.isView = false;
        }
        // newList.push(x)
      });
    },
    showViewClose(index) {
      this.examAcademicList.forEach((x, i) => {
        if (i == index) {
          x.isView = false;
        }

        // newList.push(x)
      });
    },
},

};
</script>
